import Box from '@mui/material/Box';
import Grid from '@mui/system/Grid';

import Link from '~/components/Link';
import { styled } from '~/helpers/styled';

// eslint-disable-next-line import/prefer-default-export
export const SchoolSearchContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: theme.spacing(2),
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.branding.navy[500],
  textDecoration: 'underline',
}));

export const StyledGrid = styled(Grid)(() => ({
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  padding: '24px',
}));
